<template lang="pug">
.text-input(:class="{ active: modelValue, error: hasErrors }")
  input(:value="modelValue", @input="emitChange", v-bind="$attrs")

  span.label {{ label }}
  span.error {{ errorMessage }}
</template>
<script setup lang="ts">
import { computed } from 'vue'

import type { PropType } from 'vue'
import type { ErrorObject } from '@vuelidate/core'
import type { TextMask } from '~/scripts/masks/masks'

const props = defineProps({
  modelValue: String,
  label: String,
  errors: Object as PropType<ErrorObject[]>,
  mask: Function as PropType<TextMask>
})

const emit = defineEmits(['update:modelValue'])

const hasErrors = computed(() => (props.errors?.length ?? 0) > 0);
const errorMessage = computed(() => hasErrors.value ? props.errors?.[0].$message : "" );

const emitChange = (event: Event) => {
  const target = (event.target as HTMLInputElement)
  const value = target.value
  const maskedValue = props.mask ? props.mask(value) : value

  emit("update:modelValue", maskedValue)

  if (props.modelValue == maskedValue) {
    target.value = maskedValue
  }
}
</script>
<style lang="sass" scoped>
@import '~/assets/styles/variables.sass'

.text-input
  position: relative
  display: inline-block
  height: 56px
  padding: 16px 0
  box-sizing: border-box
  width: 100%

  input
    height: 24px
    width: 100%
    box-sizing: border-box
    border: 0
    border-radius: 0
    border-bottom: 2px solid $beige
    padding: 0 4px
    outline: none
    transition: border-bottom-color .16s ease-in
    font-family: $primary-font-family
    font-size: 14px
    background-color: rgba(255, 255, 255, 0)

    &:required, &:invalid
      box-shadow: none

    &:-webkit-autofill
      -webkit-box-shadow: 0 0 0 36px white inset !important

    &[disabled]
      background-color: rgba(239, 239, 239, 0.3)
      border-bottom-color: #F5F5F5

    &:focus
      border-bottom-color: $magenta

      & ~ span.label
        transform: translateY(-14px) scale(.8)

  span.label
    position: absolute
    top: 16px
    left: 0
    height: 16px
    font-family: $primary-font-family
    font-size: 14px
    font-style: italic
    color: $labelColor
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    pointer-events: none
    transform-origin: top left
    transform: translateY(0) scale(1)
    transition: transform .16s ease-in-out, color .16s ease-in-out

  span.error
    position: absolute
    top: 32px
    left: 0
    font-size: 12px
    color: $errorColor
    opacity: 0
    transform: translateY(0)
    transition: opacity .16s ease-in-out, transform .16s ease-in-out

  &.active
    span.label
      transform: translateY(-14px) scale(.8)

  &.error
    input
      border-bottom-color: $errorColor

    span.label
      color: $errorColor

    span.error
      opacity: 1
      transform: translateY(8px)
</style>
